var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),_c('div',{staticClass:"mt-5 flex flex-col space-y-8"},[_c('FacetSwitch',{attrs:{"label":"Sportart","onValueChange":_vm.onValueChange}},[_c('ais-menu',{attrs:{"attribute":"sportType"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var items = ref.items;
          var canToggleShowMore = ref.canToggleShowMore;
          var isShowingMore = ref.isShowingMore;
          var toggleShowMore = ref.toggleShowMore;
          var refine = ref.refine;
          var createURL = ref.createURL;
return [_c('Tabs',{attrs:{"tabs":items,"refine":refine,"createURL":createURL}})]}}])})],1),_c('FacetSwitch',{attrs:{"label":"Zeitraum","onValueChange":_vm.onValueChange}},[_c('v-date-picker',{attrs:{"is-range":"","min-date":new Date(),"locale":"de","attributes":_vm.attrs},model:{value:(_vm.range),callback:function ($$v) {_vm.range=$$v},expression:"range"}}),_c('ais-configure',{attrs:{"numericRefinements":_vm.startRefinement}})],1),_c('FacetSwitch',{attrs:{"label":"Region","onValueChange":_vm.onValueChange}},[_c('vue-google-autocomplete',{staticClass:"mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm",attrs:{"id":"startPlace","placeholder":"Region","value":_vm.location.description,"types":"(regions)"},on:{"placechanged":_vm.getAddressData}}),(_vm.location.latitude && _vm.location.longitude)?_c('ais-configure',{attrs:{"aroundLatLng":[_vm.location.latitude, _vm.location.longitude],"aroundRadius":25 * 1000}}):_vm._e()],1),_c('FacetSwitch',{attrs:{"label":"Distanz","onValueChange":_vm.onValueChange}},[_c('range-input',{attrs:{"attribute":"distance","minLabel":"min. km","maxLabel":"max. km"}})],1),_c('FacetSwitch',{attrs:{"label":"Pace","onValueChange":_vm.onValueChange}},[_c('range-input',{attrs:{"attribute":"pace","minLabel":"min. km/h","maxLabel":"max. km/h"}})],1),_c('FacetSwitch',{attrs:{"label":"Höhenmeter","onValueChange":_vm.onValueChange}},[_c('range-input',{attrs:{"attribute":"altitude","minLabel":"min. m","maxLabel":"max. m"}})],1),_c('FacetSwitch',{attrs:{"label":"Schwierigkeitsgrad","onValueChange":_vm.onValueChange}},[_c('ais-menu',{attrs:{"attribute":"difficultyLevel"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var items = ref.items;
          var canToggleShowMore = ref.canToggleShowMore;
          var isShowingMore = ref.isShowingMore;
          var toggleShowMore = ref.toggleShowMore;
          var refine = ref.refine;
          var createURL = ref.createURL;
return [_c('Tabs',{attrs:{"tabs":items,"refine":refine,"createURL":createURL}})]}}])})],1),_c('FacetSwitch',{attrs:{"label":"Aktive Organisatoren","onValueChange":_vm.onValueChange}},[_c('refinement-list',{attrs:{"attribute":"organizer","label":"Aktive Organisatoren","isSearchEnabled":"","searchPlaceholder":""}})],1)],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-3 text-left sm:mt-5"},[_c('h3',{staticClass:"text-2xl leading-6 font-medium text-gray-900",attrs:{"id":"modal-headline"}},[_vm._v(" Filtern ")])])}]

export { render, staticRenderFns }