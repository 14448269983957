












































import Vue from 'vue';
import Scaffold from '@/components/Mobile/Scaffold.vue';
import TourCard from '@/components/Mobile/TourCard.vue';
import FilterModal from '@/components/Mobile/FilterModal.vue';
import InfiniteHits from '@/components/Mobile/InfiniteHits.vue';
import algoliasearch from 'algoliasearch/lite';
import { history } from 'instantsearch.js/es/lib/routers';
import { simple } from 'instantsearch.js/es/lib/stateMappings';
import { mapGetters } from 'vuex';
import SegmentedControl from '@/control/SegmentedControl.vue';
import AddIcon from '@/assets/mobile/icons/add-icon.svg';
import EventCard from '@/event/EventCard.vue';
import RideeEvent from '@/event/event';
import RawRideeEvent from '@/event/event.raw';
import Tour from '@/dtos/tour';
import { halfADayAgo } from '@/utils/date_time';
import EntityType from '@/common/entity.type';

export default Vue.extend({
  components: {
    Scaffold,
    TourCard,
    FilterModal,
    InfiniteHits,
    SegmentedControl,
    AddIcon,
    EventCard,
  },
  props: {
    type: {
      type: EntityType,
      default() {
        return EntityType.all;
      },
    },
  },
  data() {
    return {
      searchClient: algoliasearch(
        process.env.VUE_APP_ALGOLIA_APP_ID,
        process.env.VUE_APP_ALGOLIA_API_KEY
      ),
      startRefinement: {
        //referenceDateTime is introduced and exists
        //only on Algolia. It's derived as following:
        //for tour referenceDateTime = startDateTime
        //for event referenceDateTime = endDateTime or startDateTime if endDateTime undefined
        //so ongoing multiday events are listed untill they end
        referenceDateTime: {
          '>': [halfADayAgo / 1000],
        },
      },
      routing: {
        router: history(),
        stateMapping: simple(),
      },
      filterActive: false,
      segment: EntityType.all.id,
      segments: [
        {
          title: 'Touren',
          id: EntityType.tour.id,
        },
        {
          title: 'Alle',
          id: EntityType.all.id,
        },
        {
          title: 'Events',
          id: EntityType.event.id,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      savedTourIds: 'tour/getSavedTourIds',
    }),
    newEntityType(): EntityType {
      const type = EntityType.byId(this.segment);
      return type.isEvent ? type : EntityType.tour;
    },
    showFilter(): boolean {
      return EntityType.byId(this.segment).isTour;
    },
    algoliaIndexName(): string {
      return this.segment == EntityType.event.id
        ? process.env.VUE_APP_ALGOLIA_EVENT_INDEX
        : process.env.VUE_APP_ALGOLIA_TOUR_INDEX;
    },
    typeFilter(): string | undefined {
      switch (EntityType.byId(this.segment)) {
        case EntityType.event:
          return 'isEvent:true';
        case EntityType.tour:
          return 'isEvent:false';
      }
      return undefined;
    },
  },
  mounted() {
    this.$store.dispatch('tour/bindToursRef');
    this.$store.dispatch('tour/fetchUserSavedTourIds');
  },
  methods: {
    onFilterActive(val: boolean) {
      this.filterActive = val;
    },
    eventFromAlgolia(hit: any): RideeEvent {
      return RawRideeEvent.fromAlgoliaHit(hit).toEvent();
    },
    tourFromAlgolia(hit: any): Tour {
      return Tour.fromAlgoliaHit(hit);
    },
  },
});
