






















































































































import Vue from 'vue';
import { formatShortDate } from '@/utils/date_time';
import TourMemberAvatars from './TourMemberAvatars.vue';
import TourLikeButton from './TourLikeButton.vue';
import Tour from '@/dtos/tour';
export default Vue.extend({
  name: 'TourCard',
  components: { TourMemberAvatars, TourLikeButton },
  props: {
    tour: {
      type: Tour,
      required: true,
    },
  },
  methods: {
    formatDate(input: Date): string {
      return formatShortDate(input, true);
    },
  },
});
