<template>
  <ais-range-input :attribute="attribute">
    <form
      slot-scope="{ currentRefinement, range, canRefine, refine }"
      class="flex space-x-3 w-full"
    >
      <input
        type="number"
        :min="range.min"
        :max="range.max"
        :placeholder="minLabel || ''"
        :disabled="!canRefine"
        id="min-distance"
        class="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md"
        :value="formatMinValue(currentRefinement.min, range.min)"
        @input="
          refine({
            min: $event.currentTarget.value,
            max: formatMaxValue(currentRefinement.max, range.max),
          })
        "
      />

      <input
        type="number"
        :min="range.min"
        :max="range.max"
        :placeholder="maxLabel || ''"
        :disabled="!canRefine"
        id="min-distance"
        class="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md"
        :value="formatMaxValue(currentRefinement.max, range.max)"
        @input="
          refine({
            min: formatMinValue(currentRefinement.min, range.min),
            max: $event.currentTarget.value,
          })
        "
      />
    </form>
  </ais-range-input>
</template>

<script>
export default {
  props: ['attribute', 'minLabel', 'maxLabel'],
  data() {
    return {
      minVal: '',
      maxVal: '',
    };
  },
  methods: {
    formatMinValue(minValue, minRange) {
      return minValue !== null && minValue !== minRange ? minValue : '';
    },
    formatMaxValue(maxValue, maxRange) {
      return maxValue !== null && maxValue !== maxRange ? maxValue : '';
    },
  },
};
</script>
