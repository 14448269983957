









































































































import Vue from 'vue';
import FacetSwitch from '@/components/Mobile/FacetSwitch.vue';
import RangeInput from '@/components/Mobile/RangeInput.vue';
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import Tabs from '@/components/Mobile/Tabs.vue';
import RefinementList from './RefinementList.vue';

export default Vue.extend({
  components: {
    FacetSwitch,
    RangeInput,
    Tabs,
    VueGoogleAutocomplete,
    RefinementList,
  },
  data() {
    return {
      range: {
        start: new Date(),
        end: new Date(new Date().getTime() + 60 * 60 * 24 * 1000),
      },
      attrs: [
        {
          highlight: {
            start: { fillMode: 'outline' },
            base: { fillMode: 'light' },
            end: { fillMode: 'outline' },
          },
        },
      ],
      activeFiltersCount: 0,
      location: {
        latitude: 0,
        longitude: 0,
        locality: '',
      },
      isSearching: false,
    };
  },
  props: {
    onFilterEnable: {
      type: Function,
      required: true,
    },
  },
  computed: {
    startRefinement(): any {
      return {
        startDateTime: {
          '>=': [this.range.start.getTime() / 1000],
          '<=': [this.range.end.getTime() / 1000],
        },
      };
    },
  },
  methods: {
    formatMinValue(minValue: number, minRange: number) {
      return minValue !== null && minValue !== minRange ? minValue : '';
    },
    formatMaxValue(maxValue: number, maxRange: number) {
      return maxValue !== null && maxValue !== maxRange ? maxValue : '';
    },
    onValueChange(val: boolean) {
      if (!val) {
        this.activeFiltersCount += 1;
      } else {
        if (this.activeFiltersCount > 0) {
          this.activeFiltersCount -= 1;
        }
      }
      this.onFilterEnable(this.activeFiltersCount);
    },
    getAddressData(addressData: any) {
      this.location = addressData;
    },
  },
});
