var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ais-range-input',{attrs:{"attribute":_vm.attribute},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var currentRefinement = ref.currentRefinement;
var range = ref.range;
var canRefine = ref.canRefine;
var refine = ref.refine;
return _c('form',{staticClass:"flex space-x-3 w-full"},[_c('input',{staticClass:"shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md",attrs:{"type":"number","min":range.min,"max":range.max,"placeholder":_vm.minLabel || '',"disabled":!canRefine,"id":"min-distance"},domProps:{"value":_vm.formatMinValue(currentRefinement.min, range.min)},on:{"input":function($event){refine({
          min: $event.currentTarget.value,
          max: _vm.formatMaxValue(currentRefinement.max, range.max),
        })}}}),_c('input',{staticClass:"shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md",attrs:{"type":"number","min":range.min,"max":range.max,"placeholder":_vm.maxLabel || '',"disabled":!canRefine,"id":"min-distance"},domProps:{"value":_vm.formatMaxValue(currentRefinement.max, range.max)},on:{"input":function($event){refine({
          min: _vm.formatMinValue(currentRefinement.min, range.min),
          max: $event.currentTarget.value,
        })}}})])}}])})}
var staticRenderFns = []

export { render, staticRenderFns }