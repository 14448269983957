class EntityType {
  private static _event = new EntityType();
  private static _tour = new EntityType();
  private static _all = new EntityType();

  static get event() {
    return this._event;
  }
  static get tour() {
    return this._tour;
  }
  static get all() {
    return this._all;
  }

  get isTour() {
    return this === EntityType._tour;
  }
  get isEvent() {
    return this === EntityType._event;
  }
  get isAll() {
    return this === EntityType._all;
  }

  static byId(id: string) {
    switch (id) {
      case 'tour':
        return EntityType._tour;
      case 'event':
        return EntityType._event;
      default:
        return EntityType._all;
    }
  }

  get id(): string {
    if (this.isTour) return 'tour';
    if (this.isEvent) return 'event';
    return 'all';
  }
}

export default EntityType;
