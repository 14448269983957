



























import Vue from 'vue';
export default Vue.extend({
  data() {
    return {
      enabled: false,
    };
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    onValueChange: {
      type: Function,
      required: false,
    },
  },
  methods: {
    onChange() {
      this.onValueChange(this.enabled);
      this.enabled = !this.enabled;
    },
  },
});
