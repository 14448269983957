





















import Vue from 'vue';
export default Vue.extend({
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    createURL: {
      type: Function,
      required: true,
    },
    refine: {
      type: Function,
      required: true,
    },
  },
});
