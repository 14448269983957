







































































import Vue from 'vue';
export default Vue.extend({
  props: {
    attribute: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    isSearchEnabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    smallTextSize: {
      type: Boolean,
      default: false,
      required: false,
    },
    searchPlaceholder: {
      type: String,
      required: true,
    },
  },
});
