





























































import Vue from 'vue';
import FilterForm from './FilterForm.vue';
export default Vue.extend({
  components: { FilterForm },
  props: {
    onFilterActive: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      show: false,
      filtersCount: 0,
    };
  },

  methods: {
    onFilterEnable(filtersCount: number) {
      this.filtersCount = filtersCount;
      this.onFilterActive(filtersCount > 0);
    },
  },
});
