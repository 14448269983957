



























































import Vue from 'vue';
import { formatShortDate } from '@/utils/date_time';
import RideeEvent from './event';
export default Vue.extend({
  name: 'TourCard',
  props: {
    event: {
      type: RideeEvent,
      required: true,
    },
  },
  computed: {
    organizerName(): string {
      const name = this.event.organizerUsername;
      return name.length > 15 ? name.slice(0, 8) + '.' : name;
    },
  },
  methods: {
    formatDate(input: Date): string {
      return formatShortDate(input, true);
    },
  },
});
